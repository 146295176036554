




















































































import { Component, Watch, Prop } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { Funcionario, Usuario } from '@/core/models/geral';
import { FuncionarioService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class DetalharPedidoCompra extends PageBase { 
    
    item: Funcionario = new Funcionario();
    service: FuncionarioService = new FuncionarioService();
    overlay: boolean = true;

    filtro: any = {
        funcionarioId: 0
    }

    created() {
        this.filtro.funcionarioId = Number(this.$route.params.id);
    }

    mounted() {
        this.Carregar();
    }

    Carregar(){
        this.service.ObterPorId(this.filtro.funcionarioId, "Usuario.Perfil, Endereco.Municipio.Estado, Funcao").then(
            res => {
                this.item = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }
}
